<template>
  <div class="pages-draggable">
    <draggable
        v-if="fileList && fileList.length"
        v-model="fileList"
        filter=".forbid"
        animation="300"
        :move="onMove"
        @update="handleDragEnd"
    >
      <transition-group v-if="fileList && fileList.length">
        <div class="img-item" v-for="(item, index) in fileList" :key="item.uid">
          <div class="deleted" @click="handleDeleteImg(item)"><a-icon type="close" size="20" color="#fff"/></div>
          <img :src="item.url" alt="" @click="handleShowBigImg(fileList, index)"/>
          <div v-if="isShowSwitchBtn || isShowCroppingBtn" style="margin-top: 8px">
            <a-button size="small" @click="handleUseYuanImg(item, index)">使用原图</a-button>
            <a-tooltip placement="top">
              <template slot="title">
                <span>重新裁剪</span>
              </template>
              <a-button class="ml-3" size="small" icon="switcher" @click="handleUseCroppingImg(item, index)"></a-button>
            </a-tooltip>
          </div>
        </div>
      </transition-group>
    </draggable>
    <a-upload
        :key="imgKey"
        :action="ip+'/file/upload'"
        list-type="picture-card"
        :data="postParams"
        :file-list="scoreList"
        :multiple="multipleStatus"
        :showUploadList="false"
        @change="handleChangeUploadImg"
    >
      <div v-if="this.scoreList.length < maxLength">
        <a-icon type="plus" />
        <div class="ant-upload-text">上传图片</div>
      </div>
    </a-upload>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import {mapState} from "vuex";
export default {
  props: ['list', 'maxLength',  'multipleStatus', 'isShowSwitchBtn', 'isShowCroppingBtn', 'postParams'],
  components: {draggable},
  data() {
    return {
      fileList: [],
      scoreList: [],
      imgKey: Math.random(),
      isLoading: true
    }
  },
  computed: {
    ...mapState(['ip'])
  },
  mounted() {
    this.scoreList = []
    this.fileList = []
    this.fileList = this.list
    this.scoreList = this.list
  },
  methods: {
    /** TODO 使用裁剪图 */
    async handleUseCroppingImg(item, index) {
      const foundIndex = item.url.indexOf('?')
        let tempUrl = ''
      if (foundIndex > 0) {
        tempUrl = item.url.slice(0, foundIndex)
      } else {
        tempUrl = item.url
      }
      this.$loading.show();
      const res = await this.axios.get("/dq_admin/product/getCutImageInfo", {
        params: {
          itemImg: tempUrl
        }
      })
      this.$loading.hide();
      if (res.status !== '200') return
      if (res.data && res.data.cutImgUrl) {
        this.fileList = []
        item.url = res.data.cutImgUrl
        this.scoreList[index] = item
        this.fileList = this.scoreList
        this.$message.success('裁剪成功')
        this.$emit('dragImg', this.scoreList)
      } else {
        this.$message.warn('未能检测到钱币')
      }
    },
    onMove(e) {
    },
    handleUseYuanImg(item, index) {
      const foundIndexWen = item.url.indexOf('?')
      if (foundIndexWen > 0) {
        item.url = item.url.slice(0, foundIndexWen)
        this.fileList = []
        this.scoreList[index] = item
        this.fileList = this.scoreList
        this.$emit('dragImg', this.scoreList)
      } else if (item.url.indexOf('_crop.webp') > 0) {
        const foundIndex = item.url.indexOf('_crop.webp')
        if (foundIndex > 0) {
          item.url = item.url.slice(0, foundIndex)
          item.url = item.url + '.jpg'
          this.fileList = []
          this.scoreList[index] = item
          this.fileList = this.scoreList
          this.$emit('dragImg', this.scoreList)
        }
      }  else {
        return this.$message.warn('已使用')
      }
    },
    handleDragEnd(evt) {
      // console.log('拖动前的索引 :' + evt.oldIndex)
      // console.log('拖动后的索引 :' + evt.newIndex)
      // 拖拽前索引，对应的数据
      const oldIndex = evt.oldIndex
      // 拖追后索引，对应的数据
      const newIndex = evt.newIndex
      const tempList = this.moveList(this.scoreList, oldIndex, newIndex)
      this.fileList = tempList
      this.$emit('dragImg', tempList)
    },
    /** 修改图片上窜 */
    handleChangeUploadImg({file, fileList}) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.scoreList = fileList
      this.fileList = []
      this.fileList = this.scoreList
      this.$emit('dragImg', this.scoreList)
    },
    /** 删除图片 */
    handleDeleteImg(item) {
      const tempList = this.scoreList.filter(el => {
        return el.url !== item.url
      })
      this.fileList = tempList
      this.scoreList = tempList
      this.$emit('dragImg', tempList)
    },
    /** 放大图 */
    handleShowBigImg(urls, index) {
      if (urls && urls.length > 0) {
        this.$previewImg({
          list: urls.reduce((pre, cur) => {
            const obj = { img_url: cur.url };
            pre = pre.concat(obj);
            return pre;
          }, []),
          baseImgField: "img_url",
          showMute: false,
          current: index
        });
      }
    },
    /** 数组拖拽 index是当前元素下标，tindex是拖动到的位置下标*/
    moveList(arr,index,tIndex){
      //如果当前元素在拖动目标位置的下方，先将当前元素从数组拿出，数组长度-1，我们直接给数组拖动目标位置的地方新增一个和当前元素值一样的元素，
      //我们再把数组之前的那个拖动的元素删除掉，所以要len+1
      if(index > tIndex){
        arr.splice(tIndex, 0, arr[index]);
        arr.splice(index + 1 ,1)
      }
      else{
        //如果当前元素在拖动目标位置的上方，先将当前元素从数组拿出，数组长度-1，我们直接给数组拖动目标位置+1的地方新增一个和当前元素值一样的元素，
        //这时，数组len不变，我们再把数组之前的那个拖动的元素删除掉，下标还是index
        arr.splice(tIndex + 1, 0, arr[index]);
        arr.splice(index, 1)
      }
      return arr
    }
  }
}
</script>
<style lang="scss" scoped>
.upload-box {
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 8px;
  cursor: pointer;
}
.img-item {
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .deleted {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    background-color: rgba(0, 0, 0, .3);
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
}
.pages-draggable {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.pages-draggable::v-deep .ant-upload-picture-card-wrapper {
  width: 0;
}
.ml-3 {
  margin-left: 3px;
}
</style>